









































































import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import { SfButton, SfLink } from '@storefront-ui/vue';
import VaimoTopNavigation from 'organisms/header/VaimoTopNavigation.vue';
import LazyHydrate from 'vue-lazy-hydration';

import { useCart, useUiState } from '~/composables';
import Notification from '~/diptyqueTheme/components/Notification.vue';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import VaimoHeaderSearchBar from '~/integrations/klevu-search/components/VaimoHeaderSearchBar.vue';
import cartGetters from '~/modules/checkout/getters/cartGetters';
export default defineComponent({
  name: 'VaimoHeader',
  components: {
    LazyHydrate,
    VaimoHeaderSearchBar,
    SfLink,
    SfButton,
    VaimoTopNavigation,
    Notification
  },
  emits: ['accountToggle'],
  setup() {
    const {
      app: { $gtm }
    } = useContext();
    const route = useRoute();
    const routePath = computed(() => route.value.path);
    const { toggleCartSidebar, isCartSidebarOpen } = useUiState();

    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { isDesktop } = useScreenSize();

    const triggerToggleCartSidebar = () => {
      $gtm.push({
        event: 'click_mini_cart_popin'
      });
      toggleCartSidebar('cart');
    };

    const isCheckout = computed(() => {
      const checkout = routePath.value.includes('checkout');
      const success = routePath.value.includes('checkout/thank-you');
      return checkout && !success;
    });

    const getProductsTotalQuantity = computed(() => {
      const samplesLength =
        cartGetters
          .getItems(cart.value)
          .filter((item) => cartGetters.getItemPrice(item).regular === 0)
          ?.length || 0;
      return cartGetters.getTotalItems(cart.value) - samplesLength;
    });

    onMounted(async () => {
      if (isDesktop && !routePath.value.includes('checkout')) {
        await loadCartTotalQty();
      }
    });

    const megaMenuStore = useMegaMenuMobileStore();

    return {
      getProductsTotalQuantity,
      isCartSidebarOpen,
      toggleCartSidebar,
      isCheckout,
      triggerToggleCartSidebar,
      megaMenuStore
    };
  }
});
