var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfProductCard',_vm._g(_vm._b({ref:"productCard",staticClass:"vaimo-product-card",class:[].concat( _vm.getSizeClasses ),scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('VaimoBanner',{staticClass:"vaimo-product-card__banner",attrs:{"src":_vm.image,"src-hover":_vm.hoverImage,"title":_vm.title,"link":_vm.link,"ratio":"3:4","max-optimized-width":430,"source":_vm.imageSource}}),_vm._v(" "),(_vm.isEditorialDataAvailable)?_c('div',{staticClass:"editorial-data mt-sm"},_vm._l((_vm.editorialData),function(item,index){return _c('VaimoBadge',{key:(_vm.title + "-" + index),staticClass:"font-normal"},[_vm._v("\n        "+_vm._s(item.label)+"\n      ")])}),1):_vm._e()]},proxy:true},{key:"title",fn:function(){return [_c('SfLink',{staticClass:"vaimo-product-card__title",attrs:{"link":_vm.link,"title":_vm.title,"aria-label":_vm.title}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.subTitle)?_c('SfLink',{staticClass:"vaimo-product-card__sub-title",attrs:{"link":_vm.link,"title":_vm.subTitle,"aria-label":_vm.subTitle}},[_vm._v("\n      "+_vm._s(_vm.subTitle)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vaimo-product-card__count"},[(_vm.variantName && _vm.variantName !== 'false')?_c('span',{staticClass:"capacity"},[_vm._v("\n        "+_vm._s(_vm.variantName)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.relatedProductsQnty)?_c('span',{staticClass:"qnty"},[_vm._v("\n        +"+_vm._s(_vm.relatedProductsQnty)+"\n        "+_vm._s(_vm.$t(_vm.relatedProductsQnty === 1 ? 'Model' : 'Models'))+"\n      ")]):_vm._e()])]},proxy:true},{key:"price",fn:function(){return [_c('div',{staticClass:"product-price",class:{
        'w-full h-full flex justify-between flex-col':
          !_vm.isOutOfStock || !_vm.isJpStore,
        'w-full h-full flex flex-start flex-col': _vm.isOutOfStock && _vm.isJpStore,
        'product-price__without-button':
          !_vm.isAddToCartButtonShown && !_vm.isCreateThisSetButtonShown
      }},[(_vm.isJpStore)?[_c('SfLink',{staticClass:"vaimo-product-card__price excl-tax",attrs:{"link":_vm.link,"title":_vm.basePrice,"aria-label":_vm.basePrice}},[_vm._v("\n          "+_vm._s(_vm.getProductBasePrice)+"\n        ")])]:_vm._e(),_vm._v(" "),_c('SfLink',{staticClass:"vaimo-product-card__price",attrs:{"link":_vm.link,"title":_vm.regularPrice,"aria-label":_vm.regularPrice}},[_vm._v("\n        "+_vm._s(_vm.isJpStore
            ? '(' + _vm.$t('Incl. Tax: ') + _vm.getProductPrice + ')'
            : _vm.getProductPrice)+"\n      ")]),_vm._v(" "),(_vm.isAddToCartButtonShown)?_c('VaimoButton',{staticClass:"vaimo-product-card__add-to-cart",attrs:{"variant":"primary","label-centered":true,"full-width":true,"link":("#addToCart:" + _vm.sku)},on:{"click":function($event){$event.stopPropagation();return _vm.addToCart.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$t('Add to cart'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.isCreateThisSetButtonShown)?_c('VaimoButton',{staticClass:"vaimo-product-card__add-to-cart bundle",attrs:{"variant":"primary","label-centered":true,"full-width":true},nativeOn:{"click":function($event){return _vm.goToProduct(_vm.link)}}},[_vm._v("\n        "+_vm._s(_vm.$t('Create this set'))+"\n      ")]):_vm._e()],2)]},proxy:true}])},'SfProductCard',_vm.$props,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }