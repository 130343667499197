







































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useUiState } from '~/composables/useUiState';
import type { EngravingConfig } from '~/diptyqueTheme/composable/useEngraving/types';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { addBasePath } from '~/helpers/addBasePath';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import useCart from '~/modules/checkout/composables/useCart';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
  name: 'EngravingLayer',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    EngravingLegalNotice: () => import('organisms/product/EngravingLegalNotice.vue')
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    productAvailability: {
      type: Object,
      default: null
    },
    engravingConfig: {
      type: Object as PropType<EngravingConfig>,
      required: true
    },
    activeTab: {
      required: false,
      type: String,
      default: 'position'
    },
    positionEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    positionAnimate: {
      required: false,
      type: Boolean,
      default: false
    },
    textEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    textValid: {
      required: false,
      type: Boolean,
      default: false
    },
    fontsEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    fontHandwritten: {
      required: false,
      type: Boolean,
      default: false
    },
    colorEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    image: {
      required: false,
      type: String,
      default: ''
    },
    startIndex: {
      required: false,
      type: Number,
      default: 0
    },
    template: {
      required: false,
      type: Number,
      default: null
    },
    position: {
      required: false,
      type: Number,
      default: null
    },
    posX: {
      required: false,
      type: Number,
      default: null
    },
    posY: {
      required: false,
      type: Number,
      default: null
    },
    lineOne: {
      required: false,
      type: String,
      default: ''
    },
    lineTwo: {
      required: false,
      type: String,
      default: ''
    },
    lineOneMaxLength: {
      required: false,
      type: Number,
      default: 10
    },
    lineTwoMaxLength: {
      required: false,
      type: Number,
      default: 10
    },
    isTextVertical: {
      required: false,
      type: Number,
      default: null
    },
    feFontSize: {
      required: false,
      type: Number,
      default: null
    },
    font: {
      required: false,
      type: String,
      default: ''
    },
    defaultFontName: {
      required: false,
      type: String,
      default: 'Diptyque Saint-Germain'
    },
    color: {
      required: false,
      type: String,
      default: ''
    },
    addToCartEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    isEdit: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      app: { $gtm }
    } = useContext();
    const { i18n } = useContext();
    const locale = i18n.localeProperties?.code;
    const { isDesktop, isMobile } = useScreenSize();

    const selectedImage = ref(props.image);
    const selectedActiveTab = ref(props.activeTab);
    const isPositionEnabled = ref(props.positionEnabled);
    const isPositionAnimate = ref(props.positionAnimate);
    const selectedTemplate = ref(props.template);
    const selectedPosition = ref(props.position);
    const selectedPosX = ref(props.posX);
    const selectedPosY = ref(props.posY);
    const selectedDirection = ref(props.isTextVertical);
    const selectedFontSize = ref(props.feFontSize);
    const textErrors = ref({
      lineOne: { valid: true, limit: false, errorClass: '' },
      lineTwo: { valid: true, limit: false, errorClass: '' }
    });
    const lineOneText = ref(props.lineOne);
    const lineTwoText = ref(props.lineTwo);
    const isTextEnabled = ref(props.textEnabled);
    const lineOneMaxLength = ref(props.lineOneMaxLength);
    const lineTwoMaxLength = ref(props.lineTwoMaxLength);
    const isTextValid = ref(props.textValid);
    const isFontsEnabled = ref(props.fontsEnabled);
    const isFontHandwritten = ref(props.fontHandwritten);
    const activeTip = ref<number | null>(null);
    const selectedFontFamily = ref<string | null>(null);
    const selectedFont = ref(props.font);
    const isColorEnabled = ref(props.colorEnabled);
    const selectedColor = ref(props.color);
    const isAddToCartEnabled = ref(props.addToCartEnabled);
    const { addItemToCart, loading } = useAddToCart();
    const { updateEngravingItem } = useCart();
    const productStore = useProductStore();
    const { hideEngravingInfo, isEngravingInfoOpen } = useUiState();
    const showValidationMessage = ref(false);
    const cartStore = useCartStore();
    const isMerging = computed(() => cartStore.is_data_loading);

    const closeSidebar = () => {
      $gtm.push({
        event: 'click_close_popin'
      });

      hideEngravingInfo();
    };

    const defaultImage = computed(() => {
      if (!productEngravingTemplates.value) {
        hideEngravingInfo();
        return;
      }

      const image = props.engravingConfig.template[0].image_url;
      if (image === '') {
        return addBasePath('diptyque_placeholder_2.jpg');
      }

      return image;
    });

    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength);
      }
      return text;
    };

    const setActiveTab = (tab: string, index?: number) => {
      selectedActiveTab.value = tab;
      moveTabsSlider(index);
    };

    const tabSlider = ref(null);
    const tab0 = ref(null);
    const tab1 = ref(null);
    const tab2 = ref(null);
    const tab3 = ref(null);
    const tabsWrapper = ref(null);

    const moveTabsSlider = (index?: number) => {
      let slider = tabSlider.value;
      const tabs = [tab0.value, tab1.value, tab2.value, tab3.value];
      let tabsContainer = tabsWrapper.value;

      let leftPosition = 0;
      let gap = tabsContainer
        ? parseFloat(getComputedStyle(tabsContainer).gap)
        : 0;

      if (tabs && index !== undefined && index >= 0 && index < tabs.length) {
        for (let i = 0; i < index; i++) {
          const element = tabs[i] as HTMLElement;
          leftPosition += element.clientWidth + gap;
        }

        const targetTab = tabs[index] as HTMLElement;
        if (slider && targetTab) {
          slider.style.width = targetTab.clientWidth + 'px';
          leftPosition = index > 0 ? leftPosition : 0;
          slider.style.left = leftPosition + 'px';
        }

        leftPosition = 0;
      }
    };

    onMounted(() => {
      moveTabsSlider(0);
    });

    const isNextEnabled = () => {
      if (selectedActiveTab.value === 'position') {
        return isTextEnabled.value;
      } else if (selectedActiveTab.value === 'text') {
        return (
          textErrors.value.lineOne.valid &&
          textErrors.value.lineTwo.valid &&
          lineOneText.value.length > 0
        );
      } else if (selectedActiveTab.value === 'fonts') {
        return isColorEnabled.value;
      }
      return false;
    };

    const changeTab = (isNext) => {
      if (!isNextEnabled() && isNext) {
        showValidationMessage.value = true;
        return;
      }

      const tabs = ['position', 'text', 'fonts', 'color'];
      const currentIndex = tabs.indexOf(selectedActiveTab.value);

      let nextIndex;
      if (isNext) {
        nextIndex = (currentIndex + 1) % tabs.length;
      } else {
        nextIndex = (currentIndex - 1 + tabs.length) % tabs.length;
      }

      moveTabsSlider(nextIndex);
      selectedActiveTab.value = tabs[nextIndex];
      showValidationMessage.value = false;
    };

    const productEngravingData = computed(() => props.product.engraving);

    const productEngravingTemplates = computed(
      () => props.engravingConfig?.template
    );

    const productEngravingStartingTemplate = computed(() => {
      if (isAddToCartEnabled.value) {
        return;
      }
      const engravingData = productEngravingData?.value;
      if (engravingData) {
        const templateId = engravingData.template.entity_id;
        let templateIndex = productEngravingTemplates.value.findIndex(
          (template: any) => template.entity_id === templateId
        );

        if (templateIndex < 0) {
          templateIndex = 0;
        }
        const selectedTemplate = props.engravingConfig.template[templateIndex];
        selectPosition(templateIndex);

        const engravingObj = JSON.parse(engravingData.engraving_text);
        lineOneText.value = engravingObj.line1 || '';
        lineTwoText.value = engravingObj.line2 || '';
        textValidation(lineOneText.value, true);
        isFontsEnabled.value = true;

        const fontIndex = Object.values(selectedTemplate.font).findIndex(
          (font: any) => font.value === engravingData.font.value
        );
        selectFont(fontIndex);

        const colorIndex = Object.values(selectedTemplate.color).findIndex(
          (color: any) => color.value === engravingData.color.value
        );
        selectColor(colorIndex);

        return props.engravingConfig[templateIndex];
      }

      return props.engravingConfig.template[props.startIndex];
    });

    onMounted(() => {
      const startingTemplate = productEngravingStartingTemplate.value;

      if (productEngravingData?.value) {
        return;
      }

      const positionIndex = props.engravingConfig.template.findIndex(
        (template) => template.entity_id === startingTemplate.entity_id
      );

      selectPosition(positionIndex);
      isFontsEnabled.value = true;
      selectFont(0);
      selectColor(0);
    });

    const selectPosition = (position) => {
      selectedTemplate.value = position;
      selectedPosition.value = position;
      isPositionEnabled.value = true;

      selectPreviewTextPosition();

      const animationTimeout = 500;
      isPositionAnimate.value = true;
      setTimeout(() => {
        isPositionAnimate.value = false;
      }, animationTimeout);

      textValidation(lineOneText.value, true);

      const twoLines = 2;
      if (numberOfLines.value(position) !== twoLines) {
        lineTwoText.value = '';
      }

      isTextEnabled.value = true;

      addToCartValidation();
    };

    const selectPreviewTextPosition = () => {
      const templateIndex = selectedTemplate.value;
      selectedPosX.value = props.engravingConfig?.template[templateIndex].pos_x;
      selectedPosY.value = props.engravingConfig?.template[templateIndex].pos_y;
      selectedDirection.value =
        props.engravingConfig?.template[templateIndex].is_text_vertical;
    };

    watch(
      () => selectedPosition.value,
      (newValue) => {
        selectedImage.value =
          props.engravingConfig.template[newValue].image_url;
      }
    );

    const numberOfLines = computed(() => {
      if (!props.engravingConfig) {
        return;
      }

      return (index: number) => {
        if (!index) {
          index = props.startIndex;
        }
        const template = props.engravingConfig.template[index];
        return template.number_of_lines;
      };
    });

    const lineMaxLength = computed(() => {
      if (!props.engravingConfig) {
        return;
      }

      return (index: number, lineNum: number) => {
        if (!index) {
          index = props.startIndex;
        }
        const template = props.engravingConfig.template[index];
        if (lineNum === 1) {
          lineOneMaxLength.value = template.line1_maxlength;
          return template.line1_maxlength;
        }
        lineTwoMaxLength.value = template.line2_maxlength;
        return template.line2_maxlength;
      };
    });

    const lineOneMaxTextLength = computed(
      () =>
        props?.engravingConfig?.template?.[selectedPosition.value]
          ?.line1_maxlength ?? lineOneMaxLength.value
    );
    const lineTwoMaxTextLength = computed(
      () =>
        props?.engravingConfig?.template?.[selectedPosition.value]
          ?.line2_maxlength ?? lineTwoMaxLength.value
    );

    const engraveLayerWrapper = ref(null);

    const scrollToTop = () => {
      if (isMobile.value) {
        const parentElement = engraveLayerWrapper.value?.closest(
          '.sf-sidebar__content'
        );
        if (parentElement) {
          parentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      }
    };

    const textValidation = (text: string, isLineOne: boolean) => {
      const regex = /^[A-Za-zÀÇÈÉÊËĢÎÏÖÜàâçèéêëîïöù0-9\s\-–‚„‘“’”!"&',.:;?·]+$/;
      const isValid = regex.test(text);
      const textLength = text.length;
      const maxLength = isLineOne
        ? lineOneMaxTextLength.value
        : lineTwoMaxTextLength.value;

      let errorClass = '';
      isTextValid.value = false;

      if (textLength > 0) {
        if (textLength <= maxLength && isValid) {
          isTextValid.value = true;
          isFontsEnabled.value = true;
        } else if (text.length > maxLength && isValid) {
          isTextValid.value = false;
          isFontsEnabled.value = false;
          errorClass = 'error-limit';
        } else if (text.length <= maxLength && !isValid) {
          isTextValid.value = false;
          isFontsEnabled.value = false;
          errorClass = 'error-valid';
        } else if (text.length > maxLength && !isValid) {
          isTextValid.value = false;
          isFontsEnabled.value = false;
          errorClass = 'error-limit error-valid';
        }
      } else if (!isLineOne) {
        isTextValid.value = true;
        isFontsEnabled.value = true;
      } else {
        isTextValid.value = false;
        isFontsEnabled.value = false;
      }

      const valid = isTextValid.value;
      const limit = textLength > maxLength;

      if (isLineOne) {
        textErrors.value.lineOne = { valid, limit, errorClass };
      } else {
        textErrors.value.lineTwo = { valid, limit, errorClass };
      }

      isTextHasErrors();
      addToCartValidation();

      return {
        valid: valid,
        limit: limit,
        errorClass: errorClass
      };
    };

    const isTextHasErrors = (index?: number) => {
      if (index !== undefined) {
        if (index === 0 && textErrors.value.lineOne.errorClass.length > 0) {
          isFontsEnabled.value = false;
          return true;
        } else if (
          index === 1 &&
          textErrors.value.lineTwo.errorClass.length > 0
        ) {
          isFontsEnabled.value = false;
          return true;
        }

        return false;
      }

      if (
        !index &&
        (textErrors.value.lineOne.errorClass.length > 0 ||
          textErrors.value.lineTwo.errorClass.length > 0)
      ) {
        isFontsEnabled.value = false;

        return true;
      }

      return false;
    };

    const showTip = (index: number) => {
      activeTip.value = index;
    };

    const hideTip = () => {
      activeTip.value = null;
    };

    const determineFontFamily = (font) => {
      let result = props.defaultFontName;
      if (font.value?.toLowerCase().includes('diptyque')) {
        result = props.defaultFontName;
      } else if (font.value?.toLowerCase().includes('desmond')) {
        result = 'Desmond Handwriting';
      }

      return result;
    };

    const computedFontStyles = computed(() => {
      const color = selectedColor.value;
      const fontFamily = `${selectedFontFamily.value}, Apercu Pro, sans-serif !important`;
      const fontSize =
        selectedFontSize.value > 0
          ? `${selectedFontSize.value}px !important`
          : '12px !important';

      selectedFontFamily.value === 'Desmond Handwriting'
        ? (isFontHandwritten.value = true)
        : (isFontHandwritten.value = false);

      return {
        color,
        'font-family': fontFamily,
        'font-size': fontSize
      };
    });

    const selectFont = (index) => {
      const templateIndex = selectedTemplate.value
        ? selectedTemplate.value
        : props.startIndex;
      const font = props.engravingConfig?.template[templateIndex].font[index];
      selectedFont.value = font?.value;
      selectedFontFamily.value = determineFontFamily(font);
      selectedFontSize.value = Number(font.size);
      isColorEnabled.value = true;
      addToCartValidation();
    };

    const selectColor = (index) => {
      const templateIndex = selectedTemplate.value
        ? selectedTemplate.value
        : props.startIndex;
      selectedColor.value =
        props.engravingConfig?.template[templateIndex].color[index]?.value;
      addToCartValidation();
    };

    const addToCartValidation = () => {
      if (
        selectedPosition.value !== null &&
        textErrors.value.lineOne.valid &&
        textErrors.value.lineTwo.valid &&
        lineOneText.value.length > 0 &&
        selectedFont.value.length > 0 &&
        selectedColor.value.length > 0
      ) {
        isAddToCartEnabled.value = true;

        return;
      }
      isAddToCartEnabled.value = false;

      return false;
    };

    const addProductWithEngravingToCart = (product, isEdit) => {
      const productData = isEdit ? product.product : product;
      if (isMerging.value) {
        return;
      }

      if (!isAddToCartEnabled.value) {
        showValidationMessage.value = true;
        return;
      }

      const engravingCartItems = {
        template_id:
          props.engravingConfig.template[selectedTemplate.value].entity_id,
        sku: productData.sku,
        font: selectedFont.value,
        engraving_text: JSON.stringify({
          line1: lineOneText.value,
          line2: lineTwoText.value
        }),
        color: selectedColor.value
      };

      const payload = {
        engravingCartItems: engravingCartItems
      };

      const jsonPayload = JSON.stringify(payload);

      productStore.isEngravingInfoProcessed = true;

      if (isEdit) {
        updateEngravingItem({
          product: product,
          quantity: product.quantity,
          customizable_options: jsonPayload
        });
      } else {
        addItemToCart({ product: productData, quantity: 1 }, jsonPayload);
      }
      const timeout = 1500;
      setTimeout(() => {
        if (isEngravingInfoOpen.value === true) {
          hideEngravingInfo();
        }
      }, timeout);
    };

    return {
      locale,
      loading,
      isMerging,
      isDesktop,
      closeSidebar,
      setActiveTab,
      selectedActiveTab,
      isPositionEnabled,
      isPositionAnimate,
      isNextEnabled,
      changeTab,
      tabSlider,
      tab0,
      tab1,
      tab2,
      tab3,
      tabsWrapper,
      productEngravingData,
      productEngravingStartingTemplate,
      productEngravingTemplates,
      selectedPosition,
      truncateText,
      isTextEnabled,
      lineOneText,
      lineTwoText,
      numberOfLines,
      lineMaxLength,
      scrollToTop,
      engraveLayerWrapper,
      textValidation,
      textErrors,
      isTextHasErrors,
      isTextValid,
      activeTip,
      showTip,
      hideTip,
      showValidationMessage,
      isFontsEnabled,
      isFontHandwritten,
      selectedPosX,
      selectedPosY,
      selectedDirection,
      selectedFontSize,
      selectedFont,
      determineFontFamily,
      selectedFontFamily,
      isColorEnabled,
      selectedColor,
      defaultImage,
      selectedImage,
      selectPosition,
      computedFontStyles,
      selectFont,
      selectColor,
      addToCartValidation,
      isAddToCartEnabled,
      addProductWithEngravingToCart
    };
  }
});
