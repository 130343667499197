


















































































































import { useContext, watch } from '@nuxtjs/composition-api';
import { SfInput, SfModal } from '@storefront-ui/vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import VaimoCheckbox from 'molecules/VaimoCheckbox.vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { useNewsletter, useUiState } from '~/composables';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

extend('required', {
  ...required,
  message: 'This field is required'
});

export default defineComponent({
  name: 'VaimoSubscriptionModal',
  components: {
    SfModal,
    SfInput,
    VaimoCheckbox,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    ValidationProvider,
    ValidationObserver
  },
  setup() {
    const { isSubscriptionModalVisible, toggleSubscriptionModal } =
      useUiState();
    const {
      app: { i18n, $gtm }
    } = useContext();
    const { updateSubscription } = useNewsletter();
    const storeCode = i18n.localeProperties.code;
    const isStoreDe = storeCode === 'de_eu';
    const email = ref('');
    const isCheckboxVisible = ref(false);
    const termsAccepted = ref(false);
    const statusMessage = ref('');
    const status = ref('');
    const subscribeStatusDE = ref(false);
    const showForm = computed(() => {
      if (!isStoreDe) return true;
      return !subscribeStatusDE.value;
    });

    const isStatusInvalid = computed(() => {
      return status.value === 'error' || status.value === 'failed';
    });

    const changeCheckboxVisibility = () => {
      isCheckboxVisible.value = true;
    };
    const changeTermsAcceptedFlag = () => {
      termsAccepted.value = !termsAccepted.value;
    };
    const submitForm = async () => {
      if (termsAccepted.value && email.value) {
        const result = await updateSubscription({ email: email.value });

        if (result === 'SUBSCRIBED') {
          status.value = 'success';
          statusMessage.value = i18n.t(
            'Your email address has been saved'
          ) as string;
        } else if (result === null) {
          status.value = 'failed';
          statusMessage.value = i18n.t(
            'Your email address is already registered'
          ) as string;
        } else if (result === 'NOT_ACTIVE') {
          status.value = 'success';
          statusMessage.value = i18n.t('Email sent') as string;
          if (isStoreDe) {
            subscribeStatusDE.value = true;
          }
        }
      }
    };

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    const triggerToggleSubscriptionModal = () => {
      $gtm.push({
        event: 'click_close_popin'
      });
      toggleSubscriptionModal();
    };

    watch(isSubscriptionModalVisible, (isSubscriptionModalVisibleUpdated) => {
      isSubscriptionModalVisibleUpdated &&
        $gtm.push({
          event: 'newsletter_popin'
        });
    });

    return {
      isSubscriptionModalVisible,
      submitForm,
      status,
      isStatusInvalid,
      statusMessage,
      changeTermsAcceptedFlag,
      changeCheckboxVisibility,
      isCheckboxVisible,
      termsAccepted,
      toggleSubscriptionModal,
      email,
      showForm,
      getTermsOfServiceLinks,
      isStoreDe,
      triggerToggleSubscriptionModal
    };
  }
});
