<template>
  <div
    v-show="isClient"
    class="sections-list"
    :class="{ 'full-height': fullHeight }"
  >
    <component
      v-bind="{
        sectionData: section,
        config: config[section.__typename],
        pageName
      }"
      :is="section.__typename"
      v-for="(section, index) in sections"
      :key="getSectionKey(section, index)"
      :unique-key="section.__typename + index"
      :page-type="pageType"
      class="module"
    >
    </component>
  </div>
</template>

<script lang="js">
import { computed, defineComponent } from '@nuxtjs/composition-api';
import HeroHeader from 'templates/sections/HeroHeader/index.vue';

export default defineComponent({
  name: 'SectionsList',
  components: {
    HeroHeader,
    InstitutionalPush: () => import('templates/sections/InstitutionalPush/index.vue'),
    BlockInternalNavigation: () => import('templates/sections/BlockInternalNavigation/index.vue'),
    LinkInternalNavigation: () => import('templates/sections/LinkInternalNavigation/index.vue'),
    ProductBanner: () => import('templates/sections/ProductBanner/index.vue'),
    ProductPush: () => import('templates/sections/ProductPush/index.vue'),
    ProductSlider: () => import('templates/sections/ProductSlider/index.vue'),
    EditoProductPush: () => import('templates/sections/EditoProductPush/index.vue'),
    HighlightEcom: () => import('templates/sections/HighlightEcom/index.vue'),
    EcomPush: () => import('templates/sections/EcomPush/index.vue'),
    SeoTextBlock: () => import('templates/sections/SeoTextBlock/index.vue'),
    EditorialPush: () => import('templates/sections/EditorialPush/index.vue'),
    EditorialBanner: () => import('templates/sections/EditorialBanner/index.vue'),
    EditorialCard: () => import('templates/sections/EditorialCard/index.vue'),
    Gallery: () => import('organisms/product/Gallery/ProductGallery.vue'),
    Instalike: () => import('templates/sections/Instalike/index.vue'),
    CandleDuet: () => import('organisms/VaimoCandleDuet.vue'),
    Storytelling: () => import('templates/sections/StoryTellingContent/index.vue'),
    Testimony: () => import('templates/sections/Testimony/index.vue'),
    PostCard: () => import('templates/sections/PostCard/index.vue'),
    Placeholder: () => import('templates/sections/PlaceholderSection.vue'),
    CommitmentPush: () => import('templates/sections/CommitmentPush/index.vue'),
    CollectionSlider: () => import('templates/sections/CollectionSlider/index.vue'),
    CampaignPush: () => import('templates/sections/CampaignPush/index.vue'),
    ExploreSlider: () => import('templates/sections/ExploreSlider/index.vue'),
    Flexible: () => import('templates/sections/Flexible/index.vue'),
    GoodToKnowPanel: () => import('templates/sections/GoodToKnowPanel/index.vue'),
    ArticleSection: () => import('templates/sections/ArticleSection/index.vue'),
    Paragraph: () => import('templates/sections/Paragraph/index.vue'),
    ListenToTheScent: () => import('templates/sections/ListenToTheScent/index.vue'),
    AudioStory: () => import('templates/sections/AudioStory/index.vue'),
    Spotify: () => import('templates/sections/Spotify/index.vue'),
    CollectionPush: () => import('templates/sections/CollectionPush/index.vue'),

    BaseSection: () => import('templates/sections/BaseSection.vue'),
    ProductSingleSection: () => import('templates/sections/ProductSingleSection.vue'),
    ProductsSection: () => import('templates/sections/ProductsSection.vue'),
    BundleProduct: () => import('~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue')
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    config: {
      type: Object,
      required: false,
      default: () => { return {}; }
    },
    pageName: {
      type: String,
      required: false,
      default: ''
    },
    pageType: {
      type: String,
      required: false,
      default: ''
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props, { isServer }) {
    const sections = computed(() => {
      return Array.isArray(props.items) ? props.items.filter(Boolean) : [];
    });

    const isClient = computed(() => {
      return !isServer;
    });

    const getSectionKey = (section, index) => {
      return section.__typename + (section?.sys?.id || index)
    };

    return {
      sections,
      isClient,
      getSectionKey
    };
  }
});
</script>

<style lang="scss" scoped>
.sections-list {
  max-width: 100vw;
  &.full-height {
    min-height: 100vh;
  }
  & > * {
    position: relative;
    z-index: 1;
  }
  .module {
    margin-bottom: 50px;
    @include for-screen-m-and-l {
      margin-bottom: var(--spacer-lg);
    }

    &.flexible--TableSection {
      margin-bottom: var(--spacer-3md);
      @include for-screen-m-and-l {
        margin-bottom: var(--spacer-3md);
      }
    }
  }
}
</style>
