var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"grid-layout",attrs:{"appear":"","name":"slide","tag":"div"}},[(_vm.loading)?_vm._l((4 * 3),function(n){return _c('div',{key:n,staticClass:"sf-product-card card",attrs:{"data-testid":"skeleton"}},[_c('SkeletonLoader',{attrs:{"height":((_vm.imageSize.height) + "px")}}),_vm._v(" "),_c('SkeletonLoader'),_vm._v(" "),_c('SkeletonLoader')],1)}):_vm._l((_vm.productsWithCommonProductCardProps),function(product,index){return _c('div',{key:product.uid,staticClass:"grid-item",class:_vm.contentTypeClass(product.__typename),attrs:{"id":product.sku,"tabindex":"0"}},[(product.__typename === 'SimpleProduct')?_c('VaimoProductCard',{class:{
          'with-hover': index === 1 && product.__typename === 'SimpleProduct'
        },attrs:{"image-tag":"nuxt-img","sku":product.sku,"product-type":product.__typename,"loading":false,"loading-flag":_vm.getLoadingFlag(product),"stock-status":product.stock_status,"is-salable":product.is_salable,"show-add-to-cart-button":"","sub-title":_vm.customProductGetters.getProductPLPDescription(product),"title":_vm.productGetters.getName(product),"image":_vm.getMagentoImage(_vm.productGetters.getProductThumbnailImage(product)),"editorial-data":_vm.customProductGetters.getProductEditorial(product),"regular-price":_vm.validateFraction(_vm.productGetters.getPrice(product).regular),"base-price":_vm.customProductGetters.getProductBasePrice(product),"image-width":400,"image-height":500,"link":_vm.localePath(_vm.getProductLink(product)),"nuxt-img-config":{ fit: 'cover' },"wishlist-icon":false,"is-added-to-cart":_vm.isInCart(product),"hover-image":_vm.customProductGetters.getProductHoverImage(product),"capacity":_vm.customProductGetters.getProductCapacity(product),"variant-name":_vm.customProductGetters.getProductVariantName(product),"related-products-qnty":_vm.customProductGetters.getRelatedProductsQnty(product)},on:{"addToCart":function($event){return _vm.productClick(product)}},nativeOn:{"click":function($event){_vm.onGtmSelectItem(product, index, $event); _vm.productClick(product)}}}):(product.__typename === 'BundleProduct')?_c('VaimoProductCard',{attrs:{"product-type":product.__typename,"image-tag":"nuxt-img","loading":false,"loading-flag":_vm.getLoadingFlag(product),"stock-status":product.stock_status,"is-salable":product.is_salable,"sub-title":_vm.customProductGetters.getProductPLPDescription(product),"title":_vm.productGetters.getName(product),"image":_vm.getMagentoImage(_vm.productGetters.getProductThumbnailImage(product)),"editorial-data":_vm.customProductGetters.getProductEditorial(product),"regular-price":_vm.validateFraction(
            product.price_type === 'DYNAMIC'
              ? product.custom_set_minimal_price
              : _vm.productGetters.getPrice(product).regular
          ),"base-price":_vm.getBasePrice(product),"is-dynamic-price":product.price_type === 'DYNAMIC',"image-width":400,"image-height":500,"link":_vm.localePath(_vm.getProductLink(product)),"nuxt-img-config":{ fit: 'cover' },"wishlist-icon":false,"is-added-to-cart":_vm.isInCart(product),"hover-image":_vm.customProductGetters.getProductHoverImage(product),"capacity":_vm.customProductGetters.getProductCapacity(product),"variant-name":_vm.customProductGetters.getProductVariantName(product),"related-products-qnty":_vm.customProductGetters.getRelatedProductsQnty(product),"show-add-to-cart-button":false},nativeOn:{"click":function($event){_vm.onGtmSelectItem(product, index, $event);
          _vm.productClick(product);}}}):_c('SectionsList',{attrs:{"items":[product]}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }